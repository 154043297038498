import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "51543f7a-d1be-44dd-bc54-f95a97941773"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards51543f7aD1be44ddBc54F95a97941773(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards51543f7aD1be44ddBc54F95a97941773", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "f2392a57-a1c7-4d01-afab-a77cd5249ed2"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleF2392a57A1c74d01AfabA77cd5249ed2 = "Leveling";

// id: "8d08768b-92f5-4a22-8f54-b2440d0b54c3"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages8d08768b92f54a228f54B2440d0b54c3(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages8d08768b92f54a228f54B2440d0b54c3`}>
      {props.children}
    </div>
  );
}

// id: "1fd7d21d-d373-43bd-ba18-289ef0b1d87f"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader1fd7d21dD37343bdBa18289ef0b1d87f(props: any) {
  return (
    <ShortHeaderEb5f2869243245fd9d8109af3ccf0052 parentTag="DefaultHeader1fd7d21dD37343bdBa18289ef0b1d87f" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "7d1895c5-6ecd-487b-b13e-c54923cf8d62"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62(props: any) {
  return (
    <ShortHeaderEb5f2869243245fd9d8109af3ccf0052 parentTag="MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62" header-top={<DefaultHeaderTopA5bbf311Ead948888432F386e73e558d parentTag="HeaderTop8ade6daf0e474d0b95f78ad593d1c520" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "efa48330-b8a8-497c-9e5b-229df8de3824"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyEfa48330B8a8497c9e5b229df8de3824(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyEfa48330B8a8497c9e5b229df8de3824 page-body`}>
      {props["marketingCards"] || <MarketingCards51543f7aD1be44ddBc54F95a97941773 parentTag="MarketingCardsB4811bfd0e6e45929911F09996ec0961" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "1a95c258-8e85-4c4a-9ac2-b827980b3cf5"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage1a95c2588e854c4a9ac2B827980b3cf5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage1a95c2588e854c4a9ac2B827980b3cf5 page`}>
      {props["header"] || <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="HeaderA08ee4baF53642e8889d71f0ab6c0d42" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyEfa48330B8a8497c9e5b229df8de3824 parentTag="BodyDe86c905406e40188374F954c0d9f7dc" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterE105555f819b4675A26415de5f9f0b43" {...props} />}
    </div>
  );
}

// id: "91c87bc2-75b8-40ea-aaf7-496d7a043223"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage91c87bc275b840eaAaf7496d7a043223(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage91c87bc275b840eaAaf7496d7a043223`}>
      {props["header"] || <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="HeaderEec19f5a7af2469e92d0826f37bc9f8f" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterDc49dd20B9aa4c359d9f8d6056ed7c92" {...props} />}
    </div>
  );
}

// id: "cd6eec19-c574-49a8-b790-ed8f3b9786b7"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesCd6eec19C57449a8B790Ed8f3b9786b7(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesCd6eec19C57449a8B790Ed8f3b9786b7 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62 parentTag="Header2187a773052e4ae68aff80954a4c7c8d" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav0996d5cb602447e5B798Fcd016bc5251", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterB391ef4602ed4e4a88429bf08d30b69a" {...props} />}
    </div>
  );
}

// id: "4436f239-94a7-46ac-a010-90dfa248d49b"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault4436f23994a746acA01090dfa248d49b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault4436f23994a746acA01090dfa248d49b page`}>
      {props["header"] || <MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62 parentTag="HeaderD40ffa6fA65b4053A93bE07da98a4422" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="Footer98ee6340F33b476eBa52497e30bd4f54" {...props} />}
    </div>
  );
}

// id: "0f093053-4d14-4b40-ae70-8f0c8243fabb"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage0f0930534d144b40Ae708f0c8243fabb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage0f0930534d144b40Ae708f0c8243fabb`}>
      {props["header"] || <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header26d56b5c7d83483295522adf0a2419d2" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterD43c6750972e4e11Ac09C802bbebbca1" {...props} />}
    </div>
  );
}

// id: "f6ae739d-137c-4206-a858-14be3018fced"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageF6ae739d137c4206A85814be3018fced(props: any) {
  return (
    <SecondaryPage0f0930534d144b40Ae708f0c8243fabb parentTag="ResourcesPageF6ae739d137c4206A85814be3018fced" header={<SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header3f16520cA3374ae2Ba9e1f43b282b429" title="Resources" />} {...props} />
  );
}

// id: "880503b4-25c6-434f-9aec-86eaf08a2f70"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label880503b425c6434f9aec86eaf08a2f70(props: any) {
  return (
    <DefaultLogo43360f8804074051Bc78344335eaf0bc parentTag="Label880503b425c6434f9aec86eaf08a2f70" {...props} />
  );
}

// id: "43360f88-0407-4051-bc78-344335eaf0bc"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo43360f8804074051Bc78344335eaf0bc(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/D0DOw1FVz9afTzZ_regular.png" className={`${parentTag || ""} DefaultLogo43360f8804074051Bc78344335eaf0bc logo`} alt="logo" />
  );
}

// id: "279891c9-4534-440d-a8fa-34496912a109"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote279891c94534440dA8fa34496912a109(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote279891c94534440dA8fa34496912a109`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "8799ab67-f238-44e7-a3a1-e8abc2abc640"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage8799ab67F23844e7A3a1E8abc2abc640(props: any) {
  return (
    <SecondaryPage0f0930534d144b40Ae708f0c8243fabb parentTag="JoinUsPage8799ab67F23844e7A3a1E8abc2abc640" header={<SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header830eee17B1f649db9f845bdf7eb07997" title="Join Us" />} {...props} />
  );
}

// id: "05d417c6-d8be-434a-9373-252dd63dabb7"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href05d417c6D8be434a9373252dd63dabb7 = "/";

// id: "caa28450-dd56-4736-83c0-c26a223e4b20"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterCaa28450Dd56473683c0C26a223e4b20(props: any) {
  return (
    <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterCaa28450Dd56473683c0C26a223e4b20" {...props} />
  );
}

// id: "dcb5d7bf-e8c1-4798-8153-6a55f2543918"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesDcb5d7bfE8c1479881536a55f2543918(props: any) {
  return (
    <SecondaryPage0f0930534d144b40Ae708f0c8243fabb parentTag="SignupPagesDcb5d7bfE8c1479881536a55f2543918" header={<SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header78ad7b5141a74e67A64c06553f826453" title="Join Us" />} {...props} />
  );
}

// id: "2002bbe4-0095-4379-b8bd-739ecc4c0ffa"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero2002bbe400954379B8bd739ecc4c0ffa(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero2002bbe400954379B8bd739ecc4c0ffa hero`}>
      <div className="title">
        {props["title"] || Title360ddd9b08e749bc87689a12dbcf6b60}
      </div>
    </div>
  );
}

// id: "ef9448b2-1b23-43bc-9962-be347c40337a"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderEf9448b21b2343bc9962Be347c40337a(props: any) {
  return (
    <MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62 parentTag="HeaderEf9448b21b2343bc9962Be347c40337a" {...props} />
  );
}

// id: "ff08a582-efed-4185-85ee-779ca4ec188d"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterFf08a582Efed418585ee779ca4ec188d(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterFf08a582Efed418585ee779ca4ec188d footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("LinksE0cfd94dFe1a41a2A8a9C347550af51b", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoC5ceac288e7e471aA1966fc0b4d378c8 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright012199f653924a149fd38b171a891551", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow997af359285240a8A53716becbf34311", parentTag)} {...props} />}
    </div>
  );
}

// id: "86618e88-dcbd-47d0-929c-69669b2ed429"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage86618e88Dcbd47d0929c69669b2ed429(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage86618e88Dcbd47d0929c69669b2ed429`}>
      {props["header"] || <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header1d9100b0899e49f8B9027e8fdcb8a479" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterDfe82640144e4e2aAad8243eb981293e" {...props} />}
    </div>
  );
}

// id: "63dfa23f-23a5-4091-8f4c-f9711f11139f"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages63dfa23f23a540918f4cF9711f11139f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages63dfa23f23a540918f4cF9711f11139f`}>
      {props["header"] || <MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62 parentTag="Header309e2f5f41164a78Adc166d2fa968bd2" {...props} />}
      {props.children}
    </div>
  );
}

// id: "c373bce7-db16-4ddc-ac15-083ce6c696d5"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroC373bce7Db164ddcAc15083ce6c696d5(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroC373bce7Db164ddcAc15083ce6c696d5 hero`}>
      <div className="title">
        Hoshu Dojo
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button6d1f0f773d234bd2Bbe9Eca77b369c62", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "9db5dff6-5233-4677-a568-a1b866452577"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader9db5dff652334677A568A1b866452577(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader9db5dff652334677A568A1b866452577 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopA5bbf311Ead948888432F386e73e558d parentTag="HeaderTopD1cd906793134695Ac43Ac22226b1700" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero2002bbe400954379B8bd739ecc4c0ffa parentTag="Hero387d4ac7E8194135B80aC14d8ef51f24" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "0609d91e-857f-4298-8cf1-6558d5a59b97"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage0609d91e857f42988cf16558d5a59b97(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage0609d91e857f42988cf16558d5a59b97 page`}>
      {props["header"] || <HomeHeader945e7899603648a9Bf9912af701188cb parentTag="HeaderE6eb16336b89467fBe45E7a81631478c" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack340fc4f72c364c2bBd5650624e66a363", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyEfa48330B8a8497c9e5b229df8de3824 parentTag="Body0f74a46aE39e495e99f46d3a74bf5eaa" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("QuoteAbbc2de242d14d0395ecB604887a93ba", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterA123e7bd0c094a91B6ac665327224dc3" {...props} />}
    </div>
  );
}

// id: "945e7899-6036-48a9-bf99-12af701188cb"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader945e7899603648a9Bf9912af701188cb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader945e7899603648a9Bf9912af701188cb site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopA5bbf311Ead948888432F386e73e558d parentTag="HeaderTop51ad2b4b06f84a55Af69E089758c7bc0" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroC373bce7Db164ddcAc15083ce6c696d5 parentTag="Hero1aafd1aa0653466d8b6b1e6132772851" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "e0cfd94d-fe1a-41a2-a8a9-c347550af51b"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "ff08a582-efed-4185-85ee-779ca4ec188d"
export function LinksE0cfd94dFe1a41a2A8a9C347550af51b(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksE0cfd94dFe1a41a2A8a9C347550af51b", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "387d4ac7-e819-4135-b80a-c14d8ef51f24"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "9db5dff6-5233-4677-a568-a1b866452577"
export function Hero387d4ac7E8194135B80aC14d8ef51f24(props: any) {
  return (
    <SecondaryMarketingHero2002bbe400954379B8bd739ecc4c0ffa parentTag="Hero387d4ac7E8194135B80aC14d8ef51f24" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "b391ef46-02ed-4e4a-8842-9bf08d30b69a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "cd6eec19-c574-49a8-b790-ed8f3b9786b7"
export function FooterB391ef4602ed4e4a88429bf08d30b69a(props: any) {
  return (
    <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterB391ef4602ed4e4a88429bf08d30b69a" {...props} />
  );
}

// id: "d40ffa6f-a65b-4053-a93b-e07da98a4422"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4436f239-94a7-46ac-a010-90dfa248d49b"
export function HeaderD40ffa6fA65b4053A93bE07da98a4422(props: any) {
  return (
    <MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62 parentTag="HeaderD40ffa6fA65b4053A93bE07da98a4422" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "2187a773-052e-4ae6-8aff-80954a4c7c8d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "cd6eec19-c574-49a8-b790-ed8f3b9786b7"
export function Header2187a773052e4ae68aff80954a4c7c8d(props: any) {
  return (
    <MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62 parentTag="Header2187a773052e4ae68aff80954a4c7c8d" {...props} />
  );
}

// id: "e6eb1633-6b89-467f-be45-e7a81631478c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0609d91e-857f-4298-8cf1-6558d5a59b97"
export function HeaderE6eb16336b89467fBe45E7a81631478c(props: any) {
  return (
    <HomeHeader945e7899603648a9Bf9912af701188cb parentTag="HeaderE6eb16336b89467fBe45E7a81631478c" {...props} />
  );
}

// id: "340fc4f7-2c36-4c2b-bd56-50624e66a363"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "0609d91e-857f-4298-8cf1-6558d5a59b97"
export function WelcomeBack340fc4f72c364c2bBd5650624e66a363(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack340fc4f72c364c2bBd5650624e66a363", parentTag)} {...props} />
  );
}

// id: "e105555f-819b-4675-a264-15de5f9f0b43"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "1a95c258-8e85-4c4a-9ac2-b827980b3cf5"
export function FooterE105555f819b4675A26415de5f9f0b43(props: any) {
  return (
    <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterE105555f819b4675A26415de5f9f0b43" {...props} />
  );
}

// id: "98ee6340-f33b-476e-ba52-497e30bd4f54"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4436f239-94a7-46ac-a010-90dfa248d49b"
export function Footer98ee6340F33b476eBa52497e30bd4f54(props: any) {
  return (
    <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="Footer98ee6340F33b476eBa52497e30bd4f54" {...props} />
  );
}

// id: "a123e7bd-0c09-4a91-b6ac-665327224dc3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "0609d91e-857f-4298-8cf1-6558d5a59b97"
export function FooterA123e7bd0c094a91B6ac665327224dc3(props: any) {
  return (
    <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterA123e7bd0c094a91B6ac665327224dc3" {...props} />
  );
}

// id: "26d56b5c-7d83-4832-9552-2adf0a2419d2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0f093053-4d14-4b40-ae70-8f0c8243fabb"
export function Header26d56b5c7d83483295522adf0a2419d2(props: any) {
  return (
    <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header26d56b5c7d83483295522adf0a2419d2" title="Secondary Page" {...props} />
  );
}

// id: "ed77a7fa-10ee-4c77-9073-0f792bdad7eb"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "51543f7a-d1be-44dd-bc54-f95a97941773"
export const PostSlugEd77a7fa10ee4c7790730f792bdad7eb = "home-page-posts2";

// id: "309e2f5f-4116-4a78-adc1-66d2fa968bd2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "63dfa23f-23a5-4091-8f4c-f9711f11139f"
export function Header309e2f5f41164a78Adc166d2fa968bd2(props: any) {
  return (
    <MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62 parentTag="Header309e2f5f41164a78Adc166d2fa968bd2" {...props} />
  );
}

// id: "f9f321d6-bd2e-4463-a693-074e72458646"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "a5bbf311-ead9-4888-8432-f386e73e558d"
export function LinkedLogoF9f321d6Bd2e4463A693074e72458646(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoF9f321d6Bd2e4463A693074e72458646", parentTag)} url="/" className="linked-logo" label={<DefaultLogo43360f8804074051Bc78344335eaf0bc parentTag="LabelA8adad1dB1734bcf886396096f827e9c" alt="logo" />} {...props} />
  );
}

// id: "0f74a46a-e39e-495e-99f4-6d3a74bf5eaa"
// title: ""
// type: :reference
// key: "body"
// parent_id: "0609d91e-857f-4298-8cf1-6558d5a59b97"
export function Body0f74a46aE39e495e99f46d3a74bf5eaa(props: any) {
  return (
    <MarketingBodyEfa48330B8a8497c9e5b229df8de3824 parentTag="Body0f74a46aE39e495e99f46d3a74bf5eaa" {...props} />
  );
}

// id: "1d9100b0-899e-49f8-b902-7e8fdcb8a479"
// title: ""
// type: :reference
// key: "header"
// parent_id: "86618e88-dcbd-47d0-929c-69669b2ed429"
export function Header1d9100b0899e49f8B9027e8fdcb8a479(props: any) {
  return (
    <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header1d9100b0899e49f8B9027e8fdcb8a479" title="Leveling" {...props} />
  );
}

// id: "1aafd1aa-0653-466d-8b6b-1e6132772851"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "945e7899-6036-48a9-bf99-12af701188cb"
export function Hero1aafd1aa0653466d8b6b1e6132772851(props: any) {
  return (
    <HomePageHeroC373bce7Db164ddcAc15083ce6c696d5 parentTag="Hero1aafd1aa0653466d8b6b1e6132772851" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "08d7b34d-8396-4fcf-8dec-33c6aeb3df2e"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "51543f7a-d1be-44dd-bc54-f95a97941773"
export const ButtonClass08d7b34d83964fcf8dec33c6aeb3df2e = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "0996d5cb-6024-47e5-b798-fcd016bc5251"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "cd6eec19-c574-49a8-b790-ed8f3b9786b7"
export function SecondaryNav0996d5cb602447e5B798Fcd016bc5251(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav0996d5cb602447e5B798Fcd016bc5251", parentTag)} {...props} />
  );
}

// id: "012199f6-5392-4a14-9fd3-8b171a891551"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "ff08a582-efed-4185-85ee-779ca4ec188d"
export function Copyright012199f653924a149fd38b171a891551(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright012199f653924a149fd38b171a891551", parentTag)} {...props} />
  );
}

// id: "fc627654-ea0c-46ce-b934-fbb4cf944cc2"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "eb5f2869-2432-45fd-9d81-09af3ccf0052"
export function LinkedLogoFc627654Ea0c46ceB934Fbb4cf944cc2(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoFc627654Ea0c46ceB934Fbb4cf944cc2", parentTag)} label={<DefaultLogo43360f8804074051Bc78344335eaf0bc parentTag="LabelC1f450f562774b3c8ee0F29c1e8335c8" alt="logo" />} {...props} />
  );
}

// id: "78ad7b51-41a7-4e67-a64c-06553f826453"
// title: ""
// type: :reference
// key: "header"
// parent_id: "dcb5d7bf-e8c1-4798-8153-6a55f2543918"
export function Header78ad7b5141a74e67A64c06553f826453(props: any) {
  return (
    <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header78ad7b5141a74e67A64c06553f826453" title="Join Us" {...props} />
  );
}

// id: "c67e1885-8651-49ec-a514-d71cb9edd39f"
// title: ""
// type: :text
// key: "title"
// parent_id: "1fd7d21d-d373-43bd-ba18-289ef0b1d87f"
export const TitleC67e1885865149ecA514D71cb9edd39f = null;

// id: "f901c16b-66bb-4be8-a07c-0288f5304253"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "eb5f2869-2432-45fd-9d81-09af3ccf0052"
export const BackgroundImageF901c16b66bb4be8A07c0288f5304253 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "b4811bfd-0e6e-4592-9911-f09996ec0961"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "efa48330-b8a8-497c-9e5b-229df8de3824"
export function MarketingCardsB4811bfd0e6e45929911F09996ec0961(props: any) {
  return (
    <MarketingCards51543f7aD1be44ddBc54F95a97941773 parentTag="MarketingCardsB4811bfd0e6e45929911F09996ec0961" postSlug="home-page-posts2" {...props} />
  );
}

// id: "6d1f0f77-3d23-4bd2-bbe9-eca77b369c62"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "c373bce7-db16-4ddc-ac15-083ce6c696d5"
export function Button6d1f0f773d234bd2Bbe9Eca77b369c62(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button6d1f0f773d234bd2Bbe9Eca77b369c62", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "eec19f5a-7af2-469e-92d0-826f37bc9f8f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "91c87bc2-75b8-40ea-aaf7-496d7a043223"
export function HeaderEec19f5a7af2469e92d0826f37bc9f8f(props: any) {
  return (
    <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="HeaderEec19f5a7af2469e92d0826f37bc9f8f" title="Public Events" {...props} />
  );
}

// id: "d43c6750-972e-4e11-ac09-c802bbebbca1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "0f093053-4d14-4b40-ae70-8f0c8243fabb"
export function FooterD43c6750972e4e11Ac09C802bbebbca1(props: any) {
  return (
    <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterD43c6750972e4e11Ac09C802bbebbca1" {...props} />
  );
}

// id: "dfe82640-144e-4e2a-aad8-243eb981293e"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "86618e88-dcbd-47d0-929c-69669b2ed429"
export function FooterDfe82640144e4e2aAad8243eb981293e(props: any) {
  return (
    <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterDfe82640144e4e2aAad8243eb981293e" {...props} />
  );
}

// id: "d78fdece-1248-4d0b-977a-fe82a11edb83"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "a5bbf311-ead9-4888-8432-f386e73e558d"
export function NavigationD78fdece12484d0b977aFe82a11edb83(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationD78fdece12484d0b977aFe82a11edb83", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "a08ee4ba-f536-42e8-889d-71f0ab6c0d42"
// title: ""
// type: :reference
// key: "header"
// parent_id: "1a95c258-8e85-4c4a-9ac2-b827980b3cf5"
export function HeaderA08ee4baF53642e8889d71f0ab6c0d42(props: any) {
  return (
    <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="HeaderA08ee4baF53642e8889d71f0ab6c0d42" title="About Us" {...props} />
  );
}

// id: "d1cd9067-9313-4695-ac43-ac22226b1700"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "9db5dff6-5233-4677-a568-a1b866452577"
export function HeaderTopD1cd906793134695Ac43Ac22226b1700(props: any) {
  return (
    <DefaultHeaderTopA5bbf311Ead948888432F386e73e558d parentTag="HeaderTopD1cd906793134695Ac43Ac22226b1700" {...props} />
  );
}

// id: "dc49dd20-b9aa-4c35-9d9f-8d6056ed7c92"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "91c87bc2-75b8-40ea-aaf7-496d7a043223"
export function FooterDc49dd20B9aa4c359d9f8d6056ed7c92(props: any) {
  return (
    <DefaultFooterFf08a582Efed418585ee779ca4ec188d parentTag="FooterDc49dd20B9aa4c359d9f8d6056ed7c92" {...props} />
  );
}

// id: "360ddd9b-08e7-49bc-8768-9a12dbcf6b60"
// title: ""
// type: :text
// key: "title"
// parent_id: "2002bbe4-0095-4379-b8bd-739ecc4c0ffa"
export const Title360ddd9b08e749bc87689a12dbcf6b60 = "Default Title";

// id: "3f16520c-a337-4ae2-ba9e-1f43b282b429"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f6ae739d-137c-4206-a858-14be3018fced"
export function Header3f16520cA3374ae2Ba9e1f43b282b429(props: any) {
  return (
    <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header3f16520cA3374ae2Ba9e1f43b282b429" title="Resources" {...props} />
  );
}

// id: "8e7b330e-ffc7-464d-a09d-981a6fdc6183"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "eb5f2869-2432-45fd-9d81-09af3ccf0052"
export function Navigation8e7b330eFfc7464dA09d981a6fdc6183(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation8e7b330eFfc7464dA09d981a6fdc6183", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "c5ceac28-8e7e-471a-a196-6fc0b4d378c8"
// title: ""
// type: :html
// key: "logo"
// parent_id: "ff08a582-efed-4185-85ee-779ca4ec188d"
export function LogoC5ceac288e7e471aA1966fc0b4d378c8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} LogoC5ceac288e7e471aA1966fc0b4d378c8 logo`} />
  );
}

// id: "8ade6daf-0e47-4d0b-95f7-8ad593d1c520"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "7d1895c5-6ecd-487b-b13e-c54923cf8d62"
export function HeaderTop8ade6daf0e474d0b95f78ad593d1c520(props: any) {
  return (
    <DefaultHeaderTopA5bbf311Ead948888432F386e73e558d parentTag="HeaderTop8ade6daf0e474d0b95f78ad593d1c520" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "830eee17-b1f6-49db-9f84-5bdf7eb07997"
// title: ""
// type: :reference
// key: "header"
// parent_id: "8799ab67-f238-44e7-a3a1-e8abc2abc640"
export function Header830eee17B1f649db9f845bdf7eb07997(props: any) {
  return (
    <SecondaryMarketingHeader9db5dff652334677A568A1b866452577 parentTag="Header830eee17B1f649db9f845bdf7eb07997" title="Join Us" {...props} />
  );
}

// id: "83031abf-c4af-4a96-be9c-c2280b832ae1"
// title: ""
// type: :text
// key: "alt"
// parent_id: "43360f88-0407-4051-bc78-344335eaf0bc"
export const Alt83031abfC4af4a96Be9cC2280b832ae1 = "logo";

// id: "997af359-2852-40a8-a537-16becbf34311"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "ff08a582-efed-4185-85ee-779ca4ec188d"
export function Groupflow997af359285240a8A53716becbf34311(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow997af359285240a8A53716becbf34311", parentTag)} {...props} />
  );
}

// id: "cd17d683-4758-43d4-9d51-fbe9ba58fb89"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "1fd7d21d-d373-43bd-ba18-289ef0b1d87f"
export const NavMenuSlugCd17d683475843d49d51Fbe9ba58fb89 = "marketing-primary-nav";

// id: "de86c905-406e-4018-8374-f954c0d9f7dc"
// title: ""
// type: :reference
// key: "body"
// parent_id: "1a95c258-8e85-4c4a-9ac2-b827980b3cf5"
export function BodyDe86c905406e40188374F954c0d9f7dc(props: any) {
  return (
    <MarketingBodyEfa48330B8a8497c9e5b229df8de3824 parentTag="BodyDe86c905406e40188374F954c0d9f7dc" postSlug="about-us-posts" {...props} />
  );
}

// id: "abbc2de2-42d1-4d03-95ec-b604887a93ba"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "0609d91e-857f-4298-8cf1-6558d5a59b97"
export function QuoteAbbc2de242d14d0395ecB604887a93ba(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("QuoteAbbc2de242d14d0395ecB604887a93ba", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "9216e1d7-6154-4ee6-a37e-c2067008996c"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "43360f88-0407-4051-bc78-344335eaf0bc"
export const ImageUrl9216e1d761544ee6A37eC2067008996c = "https://mp1md-pub.s3-us-west-2.amazonaws.com/content/D0DOw1FVz9afTzZ_regular.png";

// id: "c13f75b8-ea33-46b4-b5b9-117e80e42678"
// title: ""
// type: :text
// key: "title"
// parent_id: "9db5dff6-5233-4677-a568-a1b866452577"
export const TitleC13f75b8Ea3346b4B5b9117e80e42678 = "Secondary Marketing Title 1";

// id: "51ad2b4b-06f8-4a55-af69-e089758c7bc0"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "945e7899-6036-48a9-bf99-12af701188cb"
export function HeaderTop51ad2b4b06f84a55Af69E089758c7bc0(props: any) {
  return (
    <DefaultHeaderTopA5bbf311Ead948888432F386e73e558d parentTag="HeaderTop51ad2b4b06f84a55Af69E089758c7bc0" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "6416ae03-3cdc-4acb-bd98-54dbde769115"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "1aafd1aa-0653-466d-8b6b-1e6132772851"
export const WidgetId6416ae033cdc4acbBd9854dbde769115 = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "2563c3ce-1eb2-491d-b3cd-dd3a7cfb6b42"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "51ad2b4b-06f8-4a55-af69-e089758c7bc0"
export const WidgetId2563c3ce1eb2491dB3cdDd3a7cfb6b42 = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "8393052b-2dbc-4707-9259-cb30b0efadf2"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "8ade6daf-0e47-4d0b-95f7-8ad593d1c520"
export const NavMenuSlug8393052b2dbc47079259Cb30b0efadf2 = "members-primary-nav";

// id: "c2539427-29a7-435f-b593-099507af5c4f"
// title: ""
// type: :text
// key: "href"
// parent_id: "6d1f0f77-3d23-4bd2-bbe9-eca77b369c62"
export const HrefC253942729a7435fB593099507af5c4f = "/join";

// id: "b5810819-806b-40a1-b8d3-34fc19da1bc9"
// title: ""
// type: :text
// key: "title"
// parent_id: "eec19f5a-7af2-469e-92d0-826f37bc9f8f"
export const TitleB5810819806b40a1B8d334fc19da1bc9 = "Public Events";

// id: "5239c11b-26a7-435c-9c52-e15c8e5b3ca4"
// title: ""
// type: :text
// key: "label"
// parent_id: "6d1f0f77-3d23-4bd2-bbe9-eca77b369c62"
export const Label5239c11b26a7435c9c52E15c8e5b3ca4 = "Join Us";

// id: "b12ba5c2-db29-424c-93d2-eae88dcd6e42"
// title: ""
// type: :text
// key: "url"
// parent_id: "f9f321d6-bd2e-4463-a693-074e72458646"
export const UrlB12ba5c2Db29424c93d2Eae88dcd6e42 = "/";

// id: "2f631420-43af-4b97-8876-12e25d360c91"
// title: ""
// type: :text
// key: "class"
// parent_id: "d78fdece-1248-4d0b-977a-fe82a11edb83"
export const Class2f63142043af4b97887612e25d360c91 = "navigation";

// id: "32d4e1d9-80ec-413a-9bb4-1883bbe183c6"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "abbc2de2-42d1-4d03-95ec-b604887a93ba"
export const ContentSlug32d4e1d980ec413a9bb41883bbe183c6 = "home-page-quote";

// id: "b8f8ce47-0d0e-4c54-be1e-23d0aa173180"
// title: ""
// type: :text
// key: "title"
// parent_id: "26d56b5c-7d83-4832-9552-2adf0a2419d2"
export const TitleB8f8ce470d0e4c54Be1e23d0aa173180 = "Secondary Page";

// id: "2d96cb00-e2e5-423a-bdc5-9989cbc4d84b"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "e0cfd94d-fe1a-41a2-a8a9-c347550af51b"
export const NavMenuSlug2d96cb00E2e5423aBdc59989cbc4d84b = "footer-nav";

// id: "b74093da-9245-4c3b-a267-c95b74bead04"
// title: ""
// type: :text
// key: "class"
// parent_id: "f9f321d6-bd2e-4463-a693-074e72458646"
export const ClassB74093da92454c3bA267C95b74bead04 = "linked-logo";

// id: "53878777-fc19-43b8-9004-6eed7570bac4"
// title: ""
// type: :text
// key: "title"
// parent_id: "387d4ac7-e819-4135-b80a-c14d8ef51f24"
export const Title53878777Fc1943b890046eed7570bac4 = "Secondary Marketing Title 2";

// id: "8b7c0927-1f59-4c7d-a3c8-39ec29fcc96b"
// title: ""
// type: :text
// key: "title"
// parent_id: "a08ee4ba-f536-42e8-889d-71f0ab6c0d42"
export const Title8b7c09271f594c7dA3c839ec29fcc96b = "About Us";

// id: "ed42d001-dab9-4dd0-abc4-87de40710a74"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "d40ffa6f-a65b-4053-a93b-e07da98a4422"
export const NavMenuSlugEd42d001Dab94dd0Abc487de40710a74 = "members-primary-nav";

// id: "85bc6238-b716-471c-ba44-b00c60142b95"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "d78fdece-1248-4d0b-977a-fe82a11edb83"
export const NavMenuSlug85bc6238B716471cBa44B00c60142b95 = "marketing-primary-nav";

// id: "628836d9-3f2f-4158-9d94-d8cee0836493"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "b4811bfd-0e6e-4592-9911-f09996ec0961"
export const PostSlug628836d93f2f41589d94D8cee0836493 = "home-page-posts2";

// id: "c1f450f5-6277-4b3c-8ee0-f29c1e8335c8"
// title: ""
// type: :reference
// key: "label"
// parent_id: "fc627654-ea0c-46ce-b934-fbb4cf944cc2"
export function LabelC1f450f562774b3c8ee0F29c1e8335c8(props: any) {
  return (
    <DefaultLogo43360f8804074051Bc78344335eaf0bc parentTag="LabelC1f450f562774b3c8ee0F29c1e8335c8" {...props} />
  );
}

// id: "486e589f-a3a9-443d-b4c3-7f7992d0d934"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "de86c905-406e-4018-8374-f954c0d9f7dc"
export const PostSlug486e589fA3a9443dB4c37f7992d0d934 = "about-us-posts";

// id: "ff4231a1-5e40-4cd9-a7db-3be33bf90033"
// title: ""
// type: :text
// key: "title"
// parent_id: "830eee17-b1f6-49db-9f84-5bdf7eb07997"
export const TitleFf4231a15e404cd9A7db3be33bf90033 = "Join Us";

// id: "78f161ca-c641-472f-8305-637913056456"
// title: ""
// type: :text
// key: "title"
// parent_id: "1d9100b0-899e-49f8-b902-7e8fdcb8a479"
export const Title78f161caC641472f8305637913056456 = "Leveling";

// id: "103cb8f8-01da-4c19-9335-7377cbdc35fd"
// title: ""
// type: :text
// key: "title"
// parent_id: "3f16520c-a337-4ae2-ba9e-1f43b282b429"
export const Title103cb8f801da4c1993357377cbdc35fd = "Resources";

// id: "a8adad1d-b173-4bcf-8863-96096f827e9c"
// title: ""
// type: :reference
// key: "label"
// parent_id: "f9f321d6-bd2e-4463-a693-074e72458646"
export function LabelA8adad1dB1734bcf886396096f827e9c(props: any) {
  return (
    <DefaultLogo43360f8804074051Bc78344335eaf0bc parentTag="LabelA8adad1dB1734bcf886396096f827e9c" {...props} />
  );
}

// id: "6972b786-0a07-48fe-af9a-07af282631e3"
// title: ""
// type: :text
// key: "title"
// parent_id: "78ad7b51-41a7-4e67-a64c-06553f826453"
export const Title6972b7860a0748feAf9a07af282631e3 = "Join Us";

// id: "6fca0e4b-5c59-43af-ae88-8c83344c5f2d"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "8e7b330e-ffc7-464d-a09d-981a6fdc6183"
export const NavMenuSlug6fca0e4b5c5943afAe888c83344c5f2d = "members-primary-nav";

// id: "a5bbf311-ead9-4888-8432-f386e73e558d"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopA5bbf311Ead948888432F386e73e558d(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopA5bbf311Ead948888432F386e73e558d header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoF9f321d6Bd2e4463A693074e72458646", parentTag)} url="/" className="linked-logo" label={<DefaultLogo43360f8804074051Bc78344335eaf0bc parentTag="LabelA8adad1dB1734bcf886396096f827e9c" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationD78fdece12484d0b977aFe82a11edb83", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "eb5f2869-2432-45fd-9d81-09af3ccf0052"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderEb5f2869243245fd9d8109af3ccf0052(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderEb5f2869243245fd9d8109af3ccf0052 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoFc627654Ea0c46ceB934Fbb4cf944cc2", parentTag)} label={<DefaultLogo43360f8804074051Bc78344335eaf0bc parentTag="LabelC1f450f562774b3c8ee0F29c1e8335c8" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation8e7b330eFfc7464dA09d981a6fdc6183", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "2cf03b95-2331-43a9-b960-c38fe38b4887"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "945e7899-6036-48a9-bf99-12af701188cb"
export const BackgroundImage2cf03b95233143a9B960C38fe38b4887 = "https://mp1md-pub.s3-us-west-2.amazonaws.com/content/tW4KWq5tbt-QFTS_regular.jpeg";

const Components = {
  MarketingCards51543f7aD1be44ddBc54F95a97941773,
  TitleF2392a57A1c74d01AfabA77cd5249ed2,
  AdminPages8d08768b92f54a228f54B2440d0b54c3,
  DefaultHeader1fd7d21dD37343bdBa18289ef0b1d87f,
  MembersAreaHeader7d1895c56ecd487bB13eC54923cf8d62,
  MarketingBodyEfa48330B8a8497c9e5b229df8de3824,
  AboutUsPage1a95c2588e854c4a9ac2B827980b3cf5,
  PublicEventsPage91c87bc275b840eaAaf7496d7a043223,
  MemberPagesCd6eec19C57449a8B790Ed8f3b9786b7,
  MembersAreaDefault4436f23994a746acA01090dfa248d49b,
  SecondaryPage0f0930534d144b40Ae708f0c8243fabb,
  ResourcesPageF6ae739d137c4206A85814be3018fced,
  Label880503b425c6434f9aec86eaf08a2f70,
  DefaultLogo43360f8804074051Bc78344335eaf0bc,
  PageQuote279891c94534440dA8fa34496912a109,
  JoinUsPage8799ab67F23844e7A3a1E8abc2abc640,
  Href05d417c6D8be434a9373252dd63dabb7,
  FooterCaa28450Dd56473683c0C26a223e4b20,
  SignupPagesDcb5d7bfE8c1479881536a55f2543918,
  SecondaryMarketingHero2002bbe400954379B8bd739ecc4c0ffa,
  HeaderEf9448b21b2343bc9962Be347c40337a,
  DefaultFooterFf08a582Efed418585ee779ca4ec188d,
  AboutLevelingPage86618e88Dcbd47d0929c69669b2ed429,
  MessagePages63dfa23f23a540918f4cF9711f11139f,
  HomePageHeroC373bce7Db164ddcAc15083ce6c696d5,
  SecondaryMarketingHeader9db5dff652334677A568A1b866452577,
  HomePage0609d91e857f42988cf16558d5a59b97,
  HomeHeader945e7899603648a9Bf9912af701188cb,
  LinksE0cfd94dFe1a41a2A8a9C347550af51b,
  Hero387d4ac7E8194135B80aC14d8ef51f24,
  FooterB391ef4602ed4e4a88429bf08d30b69a,
  HeaderD40ffa6fA65b4053A93bE07da98a4422,
  Header2187a773052e4ae68aff80954a4c7c8d,
  HeaderE6eb16336b89467fBe45E7a81631478c,
  WelcomeBack340fc4f72c364c2bBd5650624e66a363,
  FooterE105555f819b4675A26415de5f9f0b43,
  Footer98ee6340F33b476eBa52497e30bd4f54,
  FooterA123e7bd0c094a91B6ac665327224dc3,
  Header26d56b5c7d83483295522adf0a2419d2,
  PostSlugEd77a7fa10ee4c7790730f792bdad7eb,
  Header309e2f5f41164a78Adc166d2fa968bd2,
  LinkedLogoF9f321d6Bd2e4463A693074e72458646,
  Body0f74a46aE39e495e99f46d3a74bf5eaa,
  Header1d9100b0899e49f8B9027e8fdcb8a479,
  Hero1aafd1aa0653466d8b6b1e6132772851,
  ButtonClass08d7b34d83964fcf8dec33c6aeb3df2e,
  SecondaryNav0996d5cb602447e5B798Fcd016bc5251,
  Copyright012199f653924a149fd38b171a891551,
  LinkedLogoFc627654Ea0c46ceB934Fbb4cf944cc2,
  Header78ad7b5141a74e67A64c06553f826453,
  TitleC67e1885865149ecA514D71cb9edd39f,
  BackgroundImageF901c16b66bb4be8A07c0288f5304253,
  MarketingCardsB4811bfd0e6e45929911F09996ec0961,
  Button6d1f0f773d234bd2Bbe9Eca77b369c62,
  HeaderEec19f5a7af2469e92d0826f37bc9f8f,
  FooterD43c6750972e4e11Ac09C802bbebbca1,
  FooterDfe82640144e4e2aAad8243eb981293e,
  NavigationD78fdece12484d0b977aFe82a11edb83,
  HeaderA08ee4baF53642e8889d71f0ab6c0d42,
  HeaderTopD1cd906793134695Ac43Ac22226b1700,
  FooterDc49dd20B9aa4c359d9f8d6056ed7c92,
  Title360ddd9b08e749bc87689a12dbcf6b60,
  Header3f16520cA3374ae2Ba9e1f43b282b429,
  Navigation8e7b330eFfc7464dA09d981a6fdc6183,
  LogoC5ceac288e7e471aA1966fc0b4d378c8,
  HeaderTop8ade6daf0e474d0b95f78ad593d1c520,
  Header830eee17B1f649db9f845bdf7eb07997,
  Alt83031abfC4af4a96Be9cC2280b832ae1,
  Groupflow997af359285240a8A53716becbf34311,
  NavMenuSlugCd17d683475843d49d51Fbe9ba58fb89,
  BodyDe86c905406e40188374F954c0d9f7dc,
  QuoteAbbc2de242d14d0395ecB604887a93ba,
  ImageUrl9216e1d761544ee6A37eC2067008996c,
  TitleC13f75b8Ea3346b4B5b9117e80e42678,
  HeaderTop51ad2b4b06f84a55Af69E089758c7bc0,
  WidgetId6416ae033cdc4acbBd9854dbde769115,
  WidgetId2563c3ce1eb2491dB3cdDd3a7cfb6b42,
  NavMenuSlug8393052b2dbc47079259Cb30b0efadf2,
  HrefC253942729a7435fB593099507af5c4f,
  TitleB5810819806b40a1B8d334fc19da1bc9,
  Label5239c11b26a7435c9c52E15c8e5b3ca4,
  UrlB12ba5c2Db29424c93d2Eae88dcd6e42,
  Class2f63142043af4b97887612e25d360c91,
  ContentSlug32d4e1d980ec413a9bb41883bbe183c6,
  TitleB8f8ce470d0e4c54Be1e23d0aa173180,
  NavMenuSlug2d96cb00E2e5423aBdc59989cbc4d84b,
  ClassB74093da92454c3bA267C95b74bead04,
  Title53878777Fc1943b890046eed7570bac4,
  Title8b7c09271f594c7dA3c839ec29fcc96b,
  NavMenuSlugEd42d001Dab94dd0Abc487de40710a74,
  NavMenuSlug85bc6238B716471cBa44B00c60142b95,
  PostSlug628836d93f2f41589d94D8cee0836493,
  LabelC1f450f562774b3c8ee0F29c1e8335c8,
  PostSlug486e589fA3a9443dB4c37f7992d0d934,
  TitleFf4231a15e404cd9A7db3be33bf90033,
  Title78f161caC641472f8305637913056456,
  Title103cb8f801da4c1993357377cbdc35fd,
  LabelA8adad1dB1734bcf886396096f827e9c,
  Title6972b7860a0748feAf9a07af282631e3,
  NavMenuSlug6fca0e4b5c5943afAe888c83344c5f2d,
  DefaultHeaderTopA5bbf311Ead948888432F386e73e558d,
  ShortHeaderEb5f2869243245fd9d8109af3ccf0052,
  BackgroundImage2cf03b95233143a9B960C38fe38b4887
}

export default Components;