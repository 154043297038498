const ROUTES = {
  "/": {
    "name": "HomePage0609d91e857f42988cf16558d5a59b97",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages8d08768b92f54a228f54B2440d0b54c3",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage1a95c2588e854c4a9ac2B827980b3cf5",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage86618e88Dcbd47d0929c69669b2ed429",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage91c87bc275b840eaAaf7496d7a043223",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage8799ab67F23844e7A3a1E8abc2abc640",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesCd6eec19C57449a8B790Ed8f3b9786b7",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages63dfa23f23a540918f4cF9711f11139f",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPageF6ae739d137c4206A85814be3018fced",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesDcb5d7bfE8c1479881536a55f2543918",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;